import React from 'react';
import { observer } from 'mobx-react';
import { Neo } from '@singularsystems/neo-react';
import { StringUtils, Validation } from '@singularsystems/neo-core';
import PersonContactDetailsComponentVM from './PersonContactDetailsComponentVM';
import BlueRibbon from '../../../App/Views/Components/BlueRibbon';

interface IpersonContactDetailsComponentProps {
  viewModel: PersonContactDetailsComponentVM,
}

@observer
export default class PersonContactDetailsComponent extends React.Component<IpersonContactDetailsComponentProps> {

  constructor(props: IpersonContactDetailsComponentProps) {
    super(props);
  }

  public render() {
    return (
      <div>
        {
          (!this.props.viewModel.telephoneInfo.isVerified || !this.props.viewModel.emailInfo.isVerified || !this.props.viewModel.addressInfo.isVerified) &&
          <div className='mb-4 mt-4'>
            <BlueRibbon width='100%'
              text='Please make sure you tick each field to indicate that the information is correct. If not please input updated information.'
            />
          </div>
        }
        <div className='row'>
          <div className='col-md-6'>
            <label className='align-cell-label form-label'>Cell number<span className='display-asterisk'>*</span></label>
            <div className='row'>
              <div className='col-2 col-md-2 countries-container'>
                <div className='country-codes-dropdown'>
                  <Neo.AutoCompleteDropDown
                    maxMenuHeight={200}
                    bind={this.props.viewModel.meta.countryCodeValueId}
                    items={this.props.viewModel.helpersService.countryCodes}
                    onItemSelected={(countryCode) => {
                      this.props.viewModel.setCountryValueForProfile(countryCode?.CountryCode ?? "");
                      this.props.viewModel.helpersService.cleanUpContactNumberForProfile(this.props.viewModel.telephoneInfo);
                      this.props.viewModel.onCountryCodeSelected()
                    }}
                  />
                </div>
              </div>
              <div className='col-10 col-md-10'>
                <Neo.FormContext validationDisplayMode={Validation.DisplayMode.Always}>
                  <Neo.FormGroup
                    className='input-form-group cell-info'
                    bind={this.props.viewModel.telephoneInfo?.meta.contactNumberBase}
                    suppressLabel
                    placeholder={"Enter cell number"}
                    onChange={(e) => { this.props.viewModel.onTelephoneChange(e) }}
                    onBlur={(e) => {
                      this.props.viewModel.checkIfNumberChanged(e);
                      this.props.viewModel.helpersService.cleanUpContactNumberForProfile(this.props.viewModel.telephoneInfo);
                    }}
                    append={
                      (!this.props.viewModel.hidePhoneCheckbox
                      ) ?
                        <input className="form-check-input" type="checkbox"
                          onChange={(e) => { this.props.viewModel.telephoneInfo.isVerified = e.target.checked }}
                          disabled={!this.props.viewModel.telephoneInfo.isValid || StringUtils.isNullOrWhitespace(this.props.viewModel.telephoneInfo?.contactNumberBase)}
                          checked={this.props.viewModel.telephoneInfo.isVerified}
                          aria-label="Checkbox for following text input" /> : null
                    }
                    required
                  />
                </Neo.FormContext>
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <Neo.FormContext validationDisplayMode={Validation.DisplayMode.Always}>
              <Neo.FormGroup
                className='input-form-group cell-info'
                bind={this.props.viewModel.emailInfo?.meta.emailAddress}
                placeholder={"Please enter email address"}
                onChange={(e) => { this.props.viewModel.onEmailChange(e) }}
                onBlur={(e) => { this.props.viewModel.checkIfEmailChanged(e); }}
                append={
                  (!this.props.viewModel.hideEmailCheckbox
                  ) ?
                    <input className="form-check-input" type="checkbox"
                      onChange={(a) => { this.props.viewModel.emailInfo.isVerified = a.target.checked }}
                      disabled={!this.props.viewModel.emailInfo.isValid || StringUtils.isNullOrWhitespace(this.props.viewModel.emailInfo?.emailAddress)}
                      checked={this.props.viewModel.emailInfo.isVerified}
                      aria-label="Checkbox for following text input" /> : null
                }
                required
              />
            </Neo.FormContext>
          </div>
        </div>
        <div className='address-card'>
          <div className='align-address-box'>
            <div className='address-header'>Address</div>
            <Neo.FormContext validationDisplayMode={Validation.DisplayMode.Always}>
              <Neo.Loader task={this.props.viewModel.taskRunner} showSpinner={false}>
                <Neo.GridLayout>
                  <Neo.FormGroup
                    label={<span>Street name & number<span className='display-asterisk'>*</span></span>}
                    placeholder='Enter address'
                    bind={this.props.viewModel.addressInfo?.meta.addressLine1}
                    onBlur={(e) => { this.props.viewModel.checkIfAddressChanged(e); }}
                  />
                  <Neo.FormGroup
                    placeholder={'Optional'}
                    bind={this.props.viewModel.addressInfo?.meta.addressLine2}
                    onBlur={(e) => { this.props.viewModel.checkIfAddressChanged(e); }}
                  />
                  <Neo.FormGroup
                    label={<span>City/Town<span className='display-asterisk'>*</span></span>}
                    placeholder='Enter City/Town'
                    bind={this.props.viewModel.addressInfo?.meta.city}
                    onBlur={(e) => { this.props.viewModel.checkIfAddressChanged(e); }}
                  />
                  <Neo.FormGroup
                    label={<span>Suburb<span className='display-asterisk'>*</span></span>}
                    placeholder={'Enter suburb'}
                    bind={this.props.viewModel.addressInfo?.meta.addressLine3}
                    onBlur={(e) => { this.props.viewModel.checkIfAddressChanged(e); }}
                  />
                  {this.props.viewModel.countryValueId === 161 &&
                    <div className="employer-member-profile-dropdown">
                      <label className='dropdown-label'> Province/State<span className='display-asterisk'>*</span></label>
                      <Neo.AutoCompleteDropDown
                        maxMenuHeight={200}
                        bind={this.props.viewModel.meta.provinceValueId}
                        onItemSelected={(item) => this.props.viewModel.onProvinceSelected(item?.province ?? "")}
                        items={this.props.viewModel.provinces}
                        placeholder="Select province/state"
                        onBlur={(e) => { this.props.viewModel.checkIfAddressChanged(e); }}
                      />
                    </div>
                  }
                  {this.props.viewModel.countryValueId !== 161 &&
                    <div className="employer-member-profile-dropdown">
                      <label className='dropdown-label'> Province/State<span className='display-asterisk'>*</span> </label>
                      <Neo.FormGroup
                        suppressLabel={true}
                        bind={this.props.viewModel.addressInfo?.meta.province}
                        onBlur={(e) => { this.props.viewModel.checkIfAddressChanged(e); }}
                        placeholder="Select province/state"
                      />
                    </div>
                  }
                  <div className="employer-member-profile-dropdown">
                    <label className='dropdown-label'> Country<span className='display-asterisk'>*</span> </label>
                    <Neo.AutoCompleteDropDown
                      maxMenuHeight={200}
                      bind={this.props.viewModel.meta.countryValueId}
                      onItemSelected={(item) => this.props.viewModel.onCountrySelected(item?.country ?? "")}
                      items={this.props.viewModel.countries}
                      placeholder="Select Country"
                    />
                  </div>
                  <Neo.FormGroup bind={this.props.viewModel.addressInfo?.meta.postalCode}
                    label={<span>Postal code<span className='display-asterisk'>*</span></span>}
                    onBlur={(e) => { this.props.viewModel.checkIfAddressChanged(e); }}
                    placeholder={"Eg: 2199"}
                  />
                  {
                    !this.props.viewModel.hideAddressCheckbox ?
                      <div className='checkbox-container'>
                        <label className='address-tick-section'>Please tick to confirm that your address is correct</label>
                        <input
                          type="checkbox"
                          id="confirmationCheckbox"
                          className='form-check-input'
                          onChange={(event) => { this.props.viewModel.addressInfo.isVerified = event.target.checked }}
                          disabled={!this.props.viewModel.addressInfo.isValid}
                          checked={this.props.viewModel.addressInfo.isVerified}
                        />
                      </div>
                      : null
                  }
                </Neo.GridLayout>
              </Neo.Loader>
            </Neo.FormContext>
          </div>
        </div>
      </div>
    );
  }
}