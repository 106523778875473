import { Attributes, ModelBase, Rules, Validation } from '@singularsystems/neo-core';
import { IRuleContext } from '@singularsystems/neo-core/dist/Validation';

export default class AddressInfoLookup extends ModelBase {

  constructor() {
    super();
    this.makeObservable();
  }

  public sourceId: string | null = null;

  @Attributes.Nullable()
  public nameId: number | null = null;

  @Attributes.Nullable()
  public addressId: number | null = null;

  @Attributes.Nullable()
  public addressDataId: number | null = null;

  public addressType: string | null = null;

  @Attributes.Display("Street name & number")
  @Rules.Required()
  @Rules.StringLength(0, 50, "Street name & number cannot be more than 50 characters in length")
  public addressLine1: string = "";

  @Attributes.Display("Unit/apartment number & complex/building name")
  @Rules.StringLength(0, 50, "Unit/apartment number & complex/building name cannot be more than 50 characters in length")
  public addressLine2: string = "";

  @Attributes.Display("Suburb")
  @Rules.Required()
  @Rules.StringLength(0, 50, "Suburb cannot be more than 50 characters in length")
  public addressLine3: string = "";

  public subUrban: string = "";

  @Attributes.Display("City/Town")
  @Rules.Required()
  @Rules.StringLength(0, 50, "City/Town cannot be more than 50 characters in length")
  public city: string = "";

  @Rules.Required("Province/state is required")
  @Rules.StringLength(0, 50, "Province cannot be more than 50 characters in length")
  public province: string | null = null;

  @Rules.Required()
  public country: string | null = null;

  public geoRegion: string | null = null;

  @Attributes.Display("Postal code")
  @Rules.Required("Please enter postal code")
  public postalCode: string = "";

  public isPreferred: string | null = null;

  @Attributes.Date()
  public effectiveDate: Date | null = null;

  public addressTypeCode: string = "";

  public isVerified: boolean = false;

  // Client only properties / methods
  protected addBusinessRules(rules: Validation.Rules<this>) {
    super.addBusinessRules(rules);
    rules.add(this.postalCodeLimit).onProperties(c => c.postalCode);
    rules.add(this.provinceRule).onProperties(c => c.province);
  }

  private postalCodeLimit(context: IRuleContext) {
    if (this.postalCode.length != 4 && this.country === "South Africa") {
      context.addError("Postal code must be 4 characters");
    } else if (this.postalCode.length > 10 && this.country !== "South Africa") {
      context.addError("Postal code can't be greater than 10 characters");
    }
    if (this.country == "South Africa" && this.postalCode.length > 0) {
      const regex = /^[0-9]+$/;
      if (!regex.test(this.postalCode)) {
        context.addError("Postal code can only contain numbers");
      }
    }
  }

  private provinceRule(context: IRuleContext) {
    if (this.province == null) {
      context.addError("Province/state is required");
    }
  }
}